import request from "@/utils/request";
const ip = "https://pojie.ai-me.cn";

export const sendSMS = (data) =>
  request({
    url: "/op-service/v1/hj20240905/send-sms/",
    method: "post",
    ipBaseURL: ip,
    data,
  });

  export const buy = (data) =>
  request({
    url: "/op-service/v1/hj20240905/buy/",
    method: "post",
    ipBaseURL: ip,
    data,
  });
