<template>
  <van-popup v-model="visibility" class="dialog-confirm" >
    <img :src="background" :style="style" />
    <div class="footer">
      <slot name="footer">
        <img
          class="btn btn-close"
          @click="close"
          :src="closeBtnImg"
        />
        <img
          class="btn btn-submit"
          @click="handleSubmit"
          :src="submitBtnImg"
        />
        <!-- <img
          class="iconTag"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/DXAnHui/largeFlow39/shou.gif"
        /> -->
      </slot>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default:
        "https://cdn.qiumo.net.cn/allsmarketmaterial/DXAnHui/largeFlow39/dialog2.png",
    },
    width: {
      type: Number,
      default: 609,
    },
    height: {
      type: Number,
      default: 620,
    },
    closeBtnImg: {
       type: String,
      default:
        "https://cdn.qiumo.net.cn/allsmarketmaterial/DXAnHui/largeFlow39/dialog_close.png",
    },
    submitBtnImg: {
       type: String,
      default:
        "https://cdn.qiumo.net.cn/allsmarketmaterial/DXAnHui/largeFlow39/dialog_submit.png",
    },
  },
  computed: {
    style() {
      return {
        minHeight: `${this.height / 32}rem`,
        width: `${this.width / 32}rem`,
        // background: `url(${this.background}) 100% top/contain no-repeat`,
      };
    },
  },
  methods: {
    handleSubmit() {
      this.$parent.handleSubmit();
    },
    close() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-confirm {
  box-sizing: border-box;
  background-color: transparent;
  // height: auto;
  overflow: hidden;
  // padding-bottom: 20px;
  // box-sizing: border-box;

 
  .footer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 490px;
    display: flex;
    justify-content: center;

    .btn {
      flex-shrink: 0;
      width: 178px;

      &:last-child {
        padding-right: 10px;
      }

      &-close {
        width: 154px;
        padding-right: 50px;
      }

      &-submit {
            animation: scaleDraw 2s infinite;
        @keyframes scaleDraw {
          0%{
            transform: scale(1);
          }
           25%{
            transform: scale(0.9);
          }
          50%{
            transform: scale(1);
          }
           75%{
            transform: scale(0.9);
          }
           100%{
            transform: scale(1);
          }
        }
      }
    }

    .iconTag {
      position: absolute;
      width: 85px;
      right: 146px;
      bottom: -40PX;
      pointer-events: none;
    }
  }
}
</style>
